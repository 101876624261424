import {
  FormControl,
  Stack,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import Utils from "../../core/Utils";
import DecimalEntryField from "./controls/DecimalEntryField";
import { useEffect } from "react";
import { UEABusinessSetTaxPercent } from "../../core/Constants";

const SalesInfoComponent = ({
  inclusiveOfTax,
  setInclusiveOfTax,
  cost,
  setCost,
  priceExclusive,
  setPriceExclusive,
  taxPercentage,
  setTaxPercentage,
  totalPrice,
  setTotalPrice,
  taxAmount,
  setTaxAmount,
  costMandatory = true,
  customProduct = false,
  isUAEBusiness = false,
  qty,
  setQTY
}) => {
  useEffect(() => {
    calculateTaxAmount();
  }, [taxPercentage, priceExclusive, totalPrice]);

  const calculateTaxAmount = () => {
    var _totalPrice = Utils.ConvertToFloat(totalPrice);
    var _taxExcludedPrice = Utils.ConvertToFloat(priceExclusive);
    var _taxAmount = _totalPrice - _taxExcludedPrice;
    setTaxAmount(_taxAmount.toFixed(2));
  };

  const onChangeTax = (value) => {
    setTaxPercentage(value);
    var _totalPrice = Utils.ConvertToFloat(totalPrice);
    var _taxPercent = Utils.ConvertToFloat(value);
    var _taxExcludedPrice = Utils.ConvertToFloat(priceExclusive);
    if (inclusiveOfTax) {
      _taxExcludedPrice = (_totalPrice / (_taxPercent + 100)) * 100;
      setPriceExclusive(_taxExcludedPrice.toFixed(2));
    } else {
      _totalPrice = (_taxExcludedPrice * (_taxPercent + 100)) / 100;
      setTotalPrice(_totalPrice.toFixed(2));
    }
  };

  const onChangeSellingprice = (value) => {
    onChangePrice(value);
    setCost(value);
  }
  const setTaxPrecentage = () => {
    var _taxPercent;
    if (isUAEBusiness) {
      var setTaxPercent = Utils.IsNullOrEmpty(taxPercentage) ? UEABusinessSetTaxPercent : taxPercentage
      _taxPercent = Utils.ConvertToFloat(setTaxPercent);
    }
    else {
      _taxPercent = Utils.ConvertToFloat(taxPercentage);
    }
    return _taxPercent;
  }
  const onChangePrice = (value) => {
    if (!inclusiveOfTax) {
      setPriceExclusive(value);
      var _taxPercent = setTaxPrecentage();
      var _taxExcludedPrice = Utils.ConvertToFloat(value);
      var _totalPrice = (_taxExcludedPrice * (_taxPercent + 100)) / 100;
      setTotalPrice(_totalPrice.toFixed(2));
    }
  };

  const onChangeTotalPrice = (value) => {
    if (inclusiveOfTax) {
      setTotalPrice(value);
      var _taxPercent = setTaxPrecentage();
      var _totalPrice = Utils.ConvertToFloat(value);
      var _taxExcludedPrice = (_totalPrice / (_taxPercent + 100)) * 100;
      setPriceExclusive(_taxExcludedPrice.toFixed(2));
    }
  };

  if (isUAEBusiness) {
    return (
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="baseline"
          justifyContent="space-between"
          sx={{ justifyContent: "flex-end" }}
        >
          <Typography>Inclusive of Tax</Typography>
          <Switch
            sx={{ justifyContent: "flex-end" }}
            checked={inclusiveOfTax}
            onChange={() => setInclusiveOfTax(!inclusiveOfTax)}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <DecimalEntryField
              inputProps={{ style: { fontSize: 13 } }}
              label="Purchasing Cost"
              type="number"
              value={cost}
              setError={costMandatory}
              setValue={onChangeSellingprice}
            />
          </FormControl>
          <FormControl fullWidth>
            {inclusiveOfTax ? (
              <DecimalEntryField
                inputProps={{
                  style: { fontSize: 13 },
                  maxLength: 7,
                }}
                setError={true}
                label="Selling Price"
                type="number"
                value={totalPrice}
                setValue={onChangeTotalPrice}
              />
            ) : (
              <DecimalEntryField
                inputProps={{
                  style: { fontSize: 13 },
                  maxLength: 7,
                }}
                setError={true}
                label="Selling Price"
                type="number"
                value={priceExclusive}
                setValue={onChangePrice}
              />
            )}
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <DecimalEntryField
              inputProps={{ style: { fontSize: 13 } }}
              label="Quantity "
              type="number"
              value={qty}
              setError={true}
              setValue={setQTY}
            />
          </FormControl>

          <FormControl fullWidth>
            <DecimalEntryField
              inputProps={{ style: { fontSize: 13 } }}
              label="Tax Percent"
              type="number"
              value={isUAEBusiness === true ? UEABusinessSetTaxPercent : taxPercentage}
              setValue={onChangeTax}
            />
          </FormControl>
        </Stack>
        {inclusiveOfTax && (
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth>
              <TextField
                value={priceExclusive}
                label="Price Exclusive of Tax"
                variant="outlined"
                disabled
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={taxAmount}
                label="Tax Amount"
                variant="outlined"
                disabled
              />
            </FormControl>
          </Stack>
        )}
      </Stack>
    );
  }
  else if (customProduct) {
    return (
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="baseline"
          justifyContent="space-between"
          sx={{ justifyContent: "flex-end" }}
        >
          <Typography>Inclusive of Tax</Typography>
          <Switch
            sx={{ justifyContent: "flex-end" }}
            checked={inclusiveOfTax}
            onChange={() => setInclusiveOfTax(!inclusiveOfTax)}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <DecimalEntryField
              inputProps={{ style: { fontSize: 13 } }}
              label="Quantity "
              type="number"
              value={qty}
              setError={true}
              setValue={setQTY}
            />
          </FormControl>
          <FormControl fullWidth>
            {inclusiveOfTax ? (
              <DecimalEntryField
                inputProps={{
                  style: { fontSize: 13 },
                  maxLength: 7,
                }}
                setError={true}
                label="Selling Price"
                type="number"
                value={totalPrice}
                setValue={onChangeTotalPrice}
              />
            ) : (
              <DecimalEntryField
                inputProps={{
                  style: { fontSize: 13 },
                  maxLength: 7,
                }}
                setError={true}
                label="Selling Price"
                type="number"
                value={priceExclusive}
                setValue={onChangePrice}
              />
            )}
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <DecimalEntryField
              inputProps={{ style: { fontSize: 13 } }}
              label="Purchasing Cost"
              type="number"
              value={cost}
              setError={costMandatory}
              setValue={setCost}
            />
          </FormControl>
          <FormControl fullWidth>
            <DecimalEntryField
              inputProps={{ style: { fontSize: 13 } }}
              label="Tax Percent"
              type="number"
              value={taxPercentage}
              setValue={onChangeTax}
            />
          </FormControl>
        </Stack>
        {inclusiveOfTax && (
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth>
              <TextField
                value={priceExclusive}
                label="Price Exclusive of Tax"
                variant="outlined"
                disabled
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={taxAmount}
                label="Tax Amount"
                variant="outlined"
                disabled
              />
            </FormControl>
          </Stack>
        )}
      </Stack>
    );
  }
  else {
    return (
      <>
        <Stack
          direction="row"
          spacing={2}
          alignItems="baseline"
          justifyContent="space-between"
          style={{ marginBottom: "10px" }}
        >
          <strong style={{ "align-self": "end" }}>Sales Info</strong>
          <Stack
            direction="row"
            spacing={2}
            alignItems="baseline"
            justifyContent="space-between"
            sx={{ justifyContent: "flex-end" }}
          >
            <Typography>Inclusive of Tax</Typography>
            <Switch
              sx={{ justifyContent: "flex-end" }}
              checked={inclusiveOfTax}
              onChange={() => setInclusiveOfTax(!inclusiveOfTax)}
            />
          </Stack>
        </Stack>
        {!inclusiveOfTax ? (
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth>
              <DecimalEntryField
                inputProps={{ style: { fontSize: 13 } }}
                label="Cost "
                type="number"
                value={cost}
                setError={costMandatory}
                setValue={setCost}
              />
            </FormControl>
            <FormControl fullWidth>
              <DecimalEntryField
                inputProps={{
                  style: { fontSize: 13 },
                  maxLength: 7,
                }}
                setError={true}
                label="Price Exclusive of Tax"
                type="number"
                value={priceExclusive}
                setValue={onChangePrice}
              />
            </FormControl>
            <FormControl fullWidth>
              <DecimalEntryField
                inputProps={{ style: { fontSize: 13 } }}
                label="Tax Percentage"
                type="number"
                value={taxPercentage}
                setValue={onChangeTax}
              />
            </FormControl>
          </Stack>
        ) : (
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <DecimalEntryField
                  inputProps={{ style: { fontSize: 13 } }}
                  label="Cost "
                  type="number"
                  value={cost}
                  setError={costMandatory}
                  setValue={setCost}
                />
              </FormControl>
              <FormControl fullWidth>
                <DecimalEntryField
                  inputProps={{
                    style: { fontSize: 13 },
                    maxLength: 7,
                  }}
                  setError={true}
                  label="Total Price"
                  type="number"
                  value={totalPrice}
                  setValue={onChangeTotalPrice}
                />
              </FormControl>
              <FormControl fullWidth>
                <DecimalEntryField
                  inputProps={{ style: { fontSize: 13 } }}
                  label="Tax Percentage"
                  type="number"
                  value={taxPercentage}
                  setValue={onChangeTax}
                />
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl xs={8} fullWidth>
                Price Excluded Tax:
                <TextField
                  inputProps={{
                    style: { fontSize: 13 },
                  }}
                  value={priceExclusive}
                  disabled
                ></TextField>
              </FormControl>
              <FormControl xs={8} fullWidth>
                Tax Amount:
                <TextField
                  inputProps={{
                    style: { fontSize: 13 },
                    maxLength: 7,
                  }}
                  value={taxAmount}
                  disabled
                ></TextField>
              </FormControl>
            </Stack>
          </Stack>
        )}
      </>
    );
  }
};
export default SalesInfoComponent;
