import { connectToStore } from "../data/store";
import { getBusiness } from "../data/localSettingsActions";
import Utils from "./Utils";
import AppSettings from "../appsettings.json";
import moment from "moment";
import CountryHelper from "../core/CountryHelper";

const devUrl = Utils.GetServiceUrl();
//const stagingUrl = 'https://www.xuvi.com/Infopoint'
//const productionUrl = 'https://infopoint.com/Services'
export const internalGenericErrorMessage =
  "We are currently unable to reach the server. Please check your internet connection and try again shortly.";
export const logErrorOnConsole = true;
export const maxTimeout = 60000; // 60 seconds
const twoSeconds = 2000; // 2 seconds
const fourSeconds = 4000; // 4 seconds
const eightSeconds = 8000; // 8 seconds
export const apiIntervals = [twoSeconds, fourSeconds, eightSeconds];
const maxTimeoutForMedia = 600000; // 600 seconds
const geocodeUrl =
  "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCk2uVaMZuNW--_uwSDwq8GxYWNDD7KN2Q&address=";

export const getCurrentLocalDTISOString = () => {
  return moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
};

export const generateErrorResult = (response, url) => {
  if (logErrorOnConsole) {
    console.log("[ERROR] url:" + url + " response: " + response.message);
  }
  let result = {
    error: true,
    errorMessage: "Something went wrong",
    isNavigateToLogin: false,
  };
  if (!Utils.IsNullOrEmpty(response)) {
    switch (response.status) {
      case 403:
      case 401:
        result.isNavigateToLogin = true;
        result.errorMessage = response.statusText;
        break;
      default:
        result.errorMessage = response.statusText;
        break;
    }
  }
  return result;
};

export const postAsync = async (
  url,
  request = {},
  token = "",
  operation = "",
  queryId = "",
  queryParameters = [],
  mediaUpload = false
) => {
  let defaultTimeout = maxTimeout;
  if (mediaUpload) {
    defaultTimeout = maxTimeoutForMedia;
  }
  let state = connectToStore();
  let retryCount = 0;
  const maxRetries = apiIntervals.length;
  let currentDelay = 0;
  if (Utils.IsNullOrEmpty(token)) {
    token = state.token;
  }
  if (Utils.IsNullOrEmpty(operation)) {
    operation = "Execute";
  }
  let culture = "";
  if (!Utils.IsNullOrEmptyObject(state.country))
    culture = state.country.Culture;
  if (
    Utils.IsNullOrEmpty(culture) &&
    !Utils.IsNullOrEmptyObject(state.branch.country)
  )
    culture = state.branch.country.Culture;
  var apiCulture = CountryHelper.GetAPICulture(culture);

  const makeFetchCall = async (delay) => {
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
          controller.abort();
        }, defaultTimeout);
        await fetch(devUrl + url + "?culture=" + apiCulture, {
          signal: controller.signal,
          crossDomain: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            BusinessId:
              Utils.IsNullOrEmpty(state.business) == true
                ? ""
                : state.business.id,
            BranchId:
              Utils.IsNullOrEmpty(state.branch) == true
                ? ""
                : state.branch.branchId,
            UserId:
              Utils.IsNullOrEmpty(state.user) == true ? "" : state.user.id,
            Username:
              Utils.IsNullOrEmpty(state.user) == true
                ? ""
                : state.user.userName,
            Data: request,
            Operation: operation,
            CurrentLocalDT: getCurrentLocalDTISOString(),
            QueryId: queryId,
            QueryParameters: queryParameters,
            IsWebApp: true,
          }),
        })
          .then((response) => {
            clearTimeout(timeoutId);
            resolve(response);
          })
          .catch((error) => {
            clearTimeout(timeoutId);
            resolve({ status: 500, statusText: internalGenericErrorMessage });
          });
      }, delay);
    });
  };

  while (retryCount <= maxRetries) {
    try {
      const response = await makeFetchCall(currentDelay);
      if (response) {
        switch (response.status) {
          default:
            if (retryCount === maxRetries) {
              return generateErrorResult(response, devUrl + url);
            }
            break;
          case 200:
            return await response.json();
          case 401:
          case 403:
            return generateErrorResult(response, devUrl + url);
        }
      }
    } catch (error) {}
    retryCount++;
    currentDelay = apiIntervals[retryCount - 1];

    if (retryCount > maxRetries) {
      return errorResponse(internalGenericErrorMessage);
    }
  }
};

export const errorResponse = (err) => {
  return {
    error: true,
    errorMessage: err,
    isNavigateToLogin: false,
  };
};

export const postAsyncUnauthorized = async (url, request) => {
  let state = connectToStore();
  let retryCount = 0;
  const maxRetries = apiIntervals.length;
  let currentDelay = 0;
  const makeFetchCall = async (delay) => {
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
          controller.abort();
        }, maxTimeout);
        await fetch(devUrl + url, {
          signal: controller.signal,
          crossDomain: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            BusinessId:
              Utils.IsNullOrEmpty(state.business) == true
                ? ""
                : state.business.id,
            BranchId:
              Utils.IsNullOrEmpty(state.branch) == true
                ? ""
                : state.branch.branchId,
            UserId:
              Utils.IsNullOrEmpty(state.user) == true ? "" : state.user.id,
            Username:
              Utils.IsNullOrEmpty(state.user) == true
                ? ""
                : state.user.userName,
            Data: request,
            Operation: "Execute",
            CurrentLocalDT: getCurrentLocalDTISOString(),
            IsWebApp: true,
          }),
        })
          .then((response) => {
            clearTimeout(timeoutId);
            resolve(response);
          })
          .catch((error) => {
            clearTimeout(timeoutId);
            resolve({ status: 500, statusText: internalGenericErrorMessage });
          });
      }, delay);
    });
  };

  while (retryCount <= maxRetries) {
    try {
      const response = await makeFetchCall(currentDelay);
      if (response) {
        switch (response.status) {
          default:
            if (retryCount === maxRetries) {
              return generateErrorResult(response, devUrl + url);
            }
            break;
          case 200:
            return await response.json();
          case 401:
          case 403:
            return generateErrorResult(response, devUrl + url);
        }
      }
    } catch (error) {}
    retryCount++;
    currentDelay = apiIntervals[retryCount - 1];

    if (retryCount > maxRetries) {
      return errorResponse(internalGenericErrorMessage);
    }
  }
};

export const getToken = async () => {
  return await postAsyncUnauthorized("Token/GetToken", {
    Id: AppSettings.Secret,
    PublicKey: AppSettings.PublicKey,
    PublicKeyType: "AES",
  });
};

export const loadProductsFromServer = async () => {
  var business = getBusiness();
  return await postAsync(
    "Product/GetProduct",
    {},
    "",
    "GetAll",
    "AllProductsAndMaterialsForBusiness",
    [business.id]
  );
};

export const getGeocodeAddress = async (address) => {
  var addressEncoded = encodeURIComponent(address);
  var url = geocodeUrl + addressEncoded;
  let retryCount = 0;
  const maxRetries = apiIntervals.length;
  let currentDelay = 0;

  const makeFetchCall = async (delay) => {
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
          controller.abort();
        }, maxTimeout);
        await fetch(url, {
          signal: controller.signal,
        })
          .then((response) => {
            clearTimeout(timeoutId);
            resolve(response);
          })
          .catch((error) => {
            clearTimeout(timeoutId);
            resolve({ status: 500, statusText: internalGenericErrorMessage });
          });
      }, delay);
    });
  };

  while (retryCount <= maxRetries) {
    try {
      const response = await makeFetchCall(currentDelay);
      if (response) {
        switch (response.status) {
          default:
            if (retryCount === maxRetries) {
              return generateErrorResult(response, devUrl + url);
            }
            break;
          case 200:
            return await response.json();
          case 401:
          case 403:
            return generateErrorResult(response, devUrl + url);
        }
      }
    } catch (error) {}
    retryCount++;
    currentDelay = apiIntervals[retryCount - 1];

    if (retryCount > maxRetries) {
      return errorResponse(internalGenericErrorMessage);
    }
  }
};

export const getAsync = async (url, params) => {
  let state = connectToStore();
  let retryCount = 0;
  const maxRetries = apiIntervals.length;
  let currentDelay = 0;

  const makeFetchCall = async (delay) => {
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
          controller.abort();
        }, maxTimeout);
        await fetch(devUrl + url + "?" + params, {
          signal: controller.signal,
          crossDomain: true,
          method: "GET",
          headers: {
            Authorization: "Bearer " + state.token,
          },
        })
          .then((response) => {
            clearTimeout(timeoutId);
            resolve(response);
          })
          .catch((error) => {
            clearTimeout(timeoutId);
            resolve({ status: 500, statusText: internalGenericErrorMessage });
          });
      }, delay);
    });
  };

  while (retryCount <= maxRetries) {
    try {
      const response = await makeFetchCall(currentDelay);
      if (response) {
        switch (response.status) {
          default:
            if (retryCount === maxRetries) {
              return generateErrorResult(response, devUrl + url);
            }
            break;
          case 200:
            return await response.json();
          case 401:
          case 403:
            return generateErrorResult(response, devUrl + url);
        }
      }
    } catch (error) {}
    retryCount++;
    currentDelay = apiIntervals[retryCount - 1];

    if (retryCount > maxRetries) {
      return errorResponse(internalGenericErrorMessage);
    }
  }
};
