import "../Style.css";
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Utils from "../core/Utils";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Tooltip, Input } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "../bootstrap.min.css";
// import "../daterangepicker.css";
import "../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "./common/Loader";
import SideBarMenuItems from "./SideBarMenuItems";
import { useDispatch } from "react-redux";
import { connectToStore } from "../data/store";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import { getAsync, postAsync } from "../core/serviceClient";
import { setBusiness as setBusinessEntity } from "../data/localSettingsActions";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import {
  SideBarMenuNames,
  MaxDateLimit,
  VisitStatuses,
  VisitTypeEnum,
  ExpenseStatus,
  ConfirmTypes,
  UserRoles,
  MaxFileSizeLimit,
  PaymentMethodTypes,
} from "../core/Constants";
import { getAuthentication } from "../data/localSettingsActions";
import BusinessHelper from "../core/BusinessHelper";
import NumericTextField from "./common/controls/NumericTextField";
import { ImageEnlarge } from "./common/ImageEnlarge";
import UserHelper from "../core/UserHelper";
import { ExpensePage, LoginPage, OverviewPage } from "../core/PageConstants";
import CountryHelper from "../core/CountryHelper";
import DecimalEntryField from "./common/controls/DecimalEntryField";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": { width: "90%", maxHeight: "70%" },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const expenseActionsType = {
  DeletePhoto: "DeletePhoto",
  Void: "Void",
};

const Expense = (props) => {
  document.title = "Expense";
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [startDate, setStartDate] = useState(moment());
  // const [endDate, setEndDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState([moment(), moment()]);
  const [countryInfo, setCountryInfo] = useState({});
  const [addExpense, setAddExpense] = useState(false);
  const [isVoidExpense, setIsVoidExpense] = useState(true);
  const [expenseEdit, setExpenseEdit] = useState(false);
  const [assignToJobcard, setAssignToJobcard] = useState(false);
  const [business, setBusiness] = useState({});
  const [branch, setBranch] = useState({});
  const [user, setUser] = useState({});
  const [newCategory, setNewCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isAutomobileBusiness, setIsAutomobileBusiness] = useState(false);
  const [jobcards, setJobcards] = useState([]);
  const [saveExpenseDisabled, setSaveExpenseDisabled] = useState(true);
  const [alertTitle, setAlertTitle] = useState("");

  const [expenseDate, setExpenseDate] = useState(moment());
  const [expenseCategory, setExpenseCategory] = useState("");
  const [expenseJobCardNumber, setExpenseJobCardNumber] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [expenseDescription, setExpenseDescription] = useState();
  const [expenseImage, setExpenseImage] = useState("");
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [expenseList, setExpenseList] = useState([]);
  const [expenseSaved, setExpenseSaved] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [filteredExpenseList, setFilteredExpenseList] = useState([]);
  const [expenseId, setExpenseId] = useState("");
  const [expenseAction, setExpenseAction] = useState("");
  const [enlargeImage, setEnlargeImage] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    PaymentMethodTypes.Cash
  );
  const [paymentMethodsArray, setPaymentMethodsArray] = useState([]);

  //  VAT Expense
  const [addVantExpenses, setAddVantExpenses] = useState(false);
  const [trnNumber, setTrnNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [taxableAmount, setTaxableAmount] = useState("");
  const [VATNumber, setVATNumber] = useState("");
  const [isUAEBusiness, setIsUAEBusiness] = useState(false);

  const checkAuthentication = async () => {
    const localSettings = connectToStore();
    if (UserHelper.CheckPermission(ExpensePage, navigate, localSettings)) {
      const currentBus = localSettings.business;
      const currentBranch = localSettings.branch;
      const country = localSettings.country;
      var isAutomobile = BusinessHelper.IsAutomobile(currentBus);
      setIsAutomobileBusiness(isAutomobile);
      setCountryInfo(country);
      setBusiness(currentBus);
      setBranch(currentBranch);
      setUser(localSettings.user);
      var startDt = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });;
      var endDt = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      setSelectedDate([startDt, endDt]);
      if (
        !Utils.IsNullOrEmptyObject(currentBus) &&
        !Utils.IsNullOrEmptyArray(currentBus.expenseCategories)
      ) {
        setCategories(currentBus.expenseCategories);
        setNewCategory(false);
      } else {
        setNewCategory(true);
      }
      if (isAutomobile) {
        await getCustomerVisitByStatus(currentBus.id, currentBranch.branchId);
      }
      await fetchExpenseDetails(
        startDt,
        endDt,
        currentBus.id,
        currentBranch.branchId
      );
      var tempPaymentMethods = [
        PaymentMethodTypes.Cash,
        PaymentMethodTypes.Card,
        PaymentMethodTypes.NetBanking,
      ];
      if (CountryHelper.IsBusinessCountryIndia(country) === true) {
        tempPaymentMethods.push(PaymentMethodTypes.UPI);
      }
      setPaymentMethodsArray(tempPaymentMethods);
      setIsUAEBusiness(CountryHelper.IsBusinessCountryUAE(country));
    }
  };

  const columns = [
    {
      field: "description",
      headerName: "Description",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "expenseDateUtc",
      headerName: "Date",
      headerClassName: "headerStyle",
      flex: 1,
      valueGetter: (params) => moment(params.row.expenseDateUtc).format("DD-MM-YYYY"),
    },
    ...(!isUAEBusiness
      ? [
        {
          field: "jobCardId",
          headerName: "Job Card",
          headerClassName: "headerStyle",
        },
        {
          field: "category",
          headerName: "Category",
          headerClassName: "headerStyle",
        },
      ]
      : [{
        field: "trnNumber",
        type: "text",
        headerName: "Trn Number",
        headerClassName: "headerStyle",
        flex: 1,
      },
      {
        field: "invoiceNumber",
        type: "text",
        headerName: "Invoice Number",
        headerClassName: "headerStyle",
        flex: 1,
      },
      {
        field: "taxableAmount",
        type: "number",
        valueGetter: (params) => {
          return Utils.GetCurrency(params.row.taxableAmount);
        },
        headerName: "Taxable Amount",
        headerClassName: "headerStyle",
        flex: 1,
      },
      {
        field: "vat",
        type: "number",
        valueGetter: (params) => {
          return Utils.GetCurrency(params.row.vat);
        },
        headerName: "VAT",
        headerClassName: "headerStyle",
        flex: 1,
      },]),
    {
      field: "status",
      headerName: "Status",
      headerClassName: "headerStyle",
      flex: 1,
      type: "boolean",
      minWidth: 40,
      renderCell: (params) => {
        return params.row.status === "Active" ? (
          <Button variant="outlined" color="success" size="small">
            Active
          </Button>
        ) : (
          <Button variant="outlined" color="error" size="small">
            Void
          </Button>
        );
      },
    },
    {
      field: "amount",
      type: "number",
      valueGetter: (params) => {
        return Utils.GetCurrency(params.row.amount);
      },
      headerName: "Amount",
      headerClassName: "headerStyle",
      flex: 1,
    },
  ];

  useEffect(() => {
    checkAuthentication();
  }, []);

  const getCustomerVisitByStatus = async (businessId, branchId) => {
    setIsLoading(true);
    var result = await getAsync(
      "Expense/GetJobCardByStatus",
      "branchId=" +
      branchId +
      "&businessId=" +
      businessId +
      "&status=" +
      VisitStatuses.Delivered
    );
    setIsLoading(false);
    if (result.error) {
      setMsgText("Unable to load jobcards");
      setMsgOpen(true);
      return;
    }
    var visitList = result.data.customerVisitList;
    if (!Utils.IsNullOrEmptyArray(visitList)) {
      var jobcards = visitList.filter(
        (v) => v.visitType === VisitTypeEnum.Jobcard
      );
      if (!Utils.IsNullOrEmptyArray(jobcards)) {
        setJobcards(jobcards);
      }
    }
  };

  const openExpenseSetup = (expense = {}) => {
    setIsVoidExpense(true);
    if (!Utils.IsNullOrEmptyObject(expense)) {
      var expense = expense.row
      if (expense.status === ExpenseStatus.Void) {
        setIsVoidExpense(false);
      } else {
        setIsVoidExpense(true);
      }
      setExpenseId(expense.id);
      setExpenseEdit(true);
      setExpenseDate(Utils.CreateMomentFromString(expense.expenseDateUtc));
      setExpenseAmount(expense.amount);
      setExpenseCategory(expense.category);
      setExpenseJobCardNumber(expense.jobCardId);
      setExpenseDescription(expense.description);
      setAssignToJobcard(!Utils.IsNullOrEmpty(expense.jobCardId));
      setExpenseImage(expense.expenseImageData);
      if (!Utils.IsNullOrEmpty(expense.trnNumber)) {
        setAddVantExpenses(true)
      }
      else {
        setAddVantExpenses(false)
      }
      setTrnNumber(expense.trnNumber);
      setInvoiceNumber(expense.invoiceNumber);
      setTaxableAmount(expense.taxableAmount)
      setVATNumber(expense.vat);
    }
    setAddExpense(true);
  };

  useEffect(() => {
    recalculateExpenseTotalAndFilterExpenses();
  }, [expenseList, user, branch]);

  const recalculateExpenseTotalAndFilterExpenses = () => {
    if (
      Utils.IsNullOrEmptyArray(expenseList) ||
      Utils.IsNullOrEmptyObject(user) ||
      Utils.IsNullOrEmptyObject(branch)
    ) {
      setExpenseTotal(0);
      setFilteredExpenseList([]);
      return;
    }
    var filteredList = [];
    switch (user.role) {
      case UserRoles.GlobalAdmin:
        filteredList = expenseList;
        break;
      case UserRoles.BranchAdmin:
        filteredList = expenseList.filter(
          (e) => e.branchId === branch.branchId
        );
        break;
      case UserRoles.Operator:
      case UserRoles.Technician:
        filteredList = expenseList.filter((e) => e.createdByUserId === user.id);
        break;
    }
    if (Utils.IsNullOrEmptyArray(filteredList)) {
      setExpenseTotal(0);
      setFilteredExpenseList([]);
      return;
    }
    var activeExpenses = filteredList.filter(
      (e) => e.status === ExpenseStatus.Active
    );
    var total = 0;
    if (!Utils.IsNullOrEmptyArray(activeExpenses)) {
      total = activeExpenses.map((e) => e.amount).reduce((a, b) => a + b);
    }
    setExpenseTotal(total);
    setFilteredExpenseList(filteredList);
  };

  const [insDate, setInsDate] = useState();

  const UploadExpenseImage = async (event) => {
    var file = event.target.files[0];
    if (file.size > MaxFileSizeLimit) {
      setAlertTitle("Warning");
      setMsgText(
        "Unable to upload. File size exceeds the maximum limit, please ensure that it's less than 10 MB."
      );
      setMsgOpen(true);
      return;
    }
    var base64 = await convertBase64(file);
    setIsLoading(true);
    let response = await postAsync("Expense/UploadExpenseDocuments", {
      DocumentImageData: Utils.GetStringFromBase64(base64),
    }, "", "", "", [], true);
    setIsLoading(false);
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
      return;
    }
    setExpenseImage(response.data.documentImageUrl);
  };

  const deleteExpensePhoto = () => {
    setExpenseAction(expenseActionsType.DeletePhoto);
    setConfirmText("Deleted images cannot be restored. Are you sure?");
    setShowConfirm(true);
  };

  const saveExpense = async (isVoid) => {
    if (!isVoid) {
      var expenseUTC = expenseDate
        .utc()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      if (expenseUTC.isAfter(Utils.GetStartDate)) {
        setMsgText("Expense date should not be greater then current date");
        setMsgOpen(true);
        return;
      }
      var selectedVisit =
        !isAutomobileBusiness ||
          !assignToJobcard ||
          Utils.IsNullOrEmpty(expenseJobCardNumber)
          ? {}
          : jobcards.find((j) => j.jobcardNumber === expenseJobCardNumber);
      if (!Utils.IsNullOrEmptyObject(selectedVisit)) {
        var createdDT = moment(selectedVisit.createdDT).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        if (expenseUTC < createdDT) {
          setMsgText("Expense date should not be greater then jobcard date");
          setMsgOpen(true);
          return;
        }
      }
      await submitExpenseDetails("Expense created successfully");
    } else {
      setExpenseAction(expenseActionsType.Void);
      setConfirmText("Are you sure you want to void this expense?");
      setShowConfirm(true);
    }
  };

  const submitExpenseDetails = async (alertMsgText) => {
    var request = {
      UserName: user.firstName,
      Amount: Utils.ConvertToFloat(expenseAmount),
      Description: expenseDescription,
      ExpenseImageData: expenseImage,
      ExpenseId: Utils.IsNullOrEmpty(expenseId) ? "" : expenseId,
      ExpenseDate: Utils.GetISODateTimeString(
        expenseDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      ),
      Status: !expenseEdit ? ExpenseStatus.Active : ExpenseStatus.Void,
      Category: expenseCategory,
      JobCardId:
        isAutomobileBusiness &&
          assignToJobcard &&
          !Utils.IsNullOrEmpty(expenseJobCardNumber)
          ? expenseJobCardNumber
          : "",
      IsNewCategory: newCategory,
      PaymentMethod: !Utils.IsNullOrEmpty(selectedPaymentMethod) ? selectedPaymentMethod : "",
      TrnNumber: !Utils.IsNullOrEmpty(trnNumber) ? trnNumber : "",
      InvoiceNumber: !Utils.IsNullOrEmpty(invoiceNumber) ? invoiceNumber : "",
      TaxableAmount: Utils.IsNullOrEmpty(taxableAmount) ? 0 : Utils.ConvertToFloat(taxableAmount),
      VAT: Utils.IsNullOrEmpty(VATNumber) ? 0 : Utils.ConvertToFloat(VATNumber)

    };
    setIsLoading(true);
    let result = await postAsync("Expense/SaveExpense", request);
    setIsLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setMsgText(result.errorMessage);
      setMsgOpen(true);
      return;
    }
    if (result.data.isResponseSaved) {
      let businessEntityConfig = {
        ...business,
        expenseCategories: result.data.allCategories,
      };
      setBusinessEntity(businessEntityConfig, dispatch);
      setBusiness(businessEntityConfig);
      setCategories(businessEntityConfig.expenseCategories);
      setExpenseSaved(true);
      setMsgText(alertMsgText);
      setMsgOpen(true);
    } else {
      setMsgText("Expense creation failed");
      setMsgOpen(true);
    }
  };

  const closeExpenseSetup = () => {
    setExpenseId("");
    setExpenseAmount("");
    setExpenseCategory("");
    setExpenseDescription("");
    setExpenseDate(moment());
    setExpenseImage("");
    setExpenseJobCardNumber("");
    setExpenseEdit(false);
    setExpenseJobCardNumber("");
    setAssignToJobcard(false);
    setExpenseAction("");
    setTrnNumber();
    setInvoiceNumber();
    setTaxableAmount()
    setVATNumber();
    setNewCategory(Utils.IsNullOrEmptyArray(business.expenseCategories));
    setExpenseSaved(false);
    setAddExpense(false);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const fetchExpenseDetails = async (
    startDate,
    endDate,
    businessId = "",
    branchId = ""
  ) => {
    setIsLoading(true);
    var startDT = startDate
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .utc();
    var endDT = endDate
      .utc()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    if (Utils.IsNullOrEmpty(businessId)) {
      businessId = business.id;
    }
    if (Utils.IsNullOrEmpty(branchId)) {
      branchId = branch.branchId;
    }
    let result = await getAsync(
      "Expense/GetExpenseListByDateFilter",
      "businessId=" +
      businessId +
      "&branchId=" +
      branchId +
      "&expenseStartDate=" +
      Utils.GetISODateTimeString(startDT) +
      "&expenseEndDate=" +
      Utils.GetISODateTimeString(endDT)
    );
    setIsLoading(false);
    if (result.error) {
      setMsgText(result.errorMessage);
      setMsgOpen(true);
      return;
    }
    if (Utils.IsNullOrEmptyArray(result.data.expenseList)) {
      setExpenseList([]);
      return;
    }
    setExpenseList(result.data.expenseList);
  };

  // const handleDatePickerCallback = (start, end) => {
  //   let dateDiff = end.diff(start, "days");
  //   if (dateDiff > MaxDateLimit) {
  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      //setSelectedDate(dateArray);
      let dateDiff = dateArray[1].diff(dateArray[0], "days");
      if (dateDiff > MaxDateLimit) {
        setMsgText(
          "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
        );
        setMsgOpen(true);
      } else {
        setSelectedDate(dateArray);
        fetchExpenseDetails(dateArray[0], dateArray[1]);
      }
    }
  };

  const handleNewCategory = (event) => {
    if (!event.target.checked) {
      if (Utils.IsNullOrEmptyArray(business.expenseCategories)) {
        setNewCategory(true);
      } else {
        setExpenseCategory("");
        setNewCategory(false);
      }
    } else {
      setExpenseCategory("");
      setNewCategory(true);
    }
  };

  useEffect(() => {
    checkDetailsFilled();
  }, [
    expenseAmount,
    expenseCategory,
    expenseDescription,
    selectedPaymentMethod,
    VATNumber,
    taxableAmount,
    trnNumber,
    invoiceNumber,
    addVantExpenses
  ]);

  const checkDetailsFilled = () => {
    var isDetailsFilled = false;
    if (isUAEBusiness && addVantExpenses === true) {
      isDetailsFilled = !Utils.IsNullOrEmpty(VATNumber) && Utils.ConvertToFloat(taxableAmount) > 0 && Utils.ConvertToFloat(trnNumber) > 0 && !Utils.IsNullOrEmpty(invoiceNumber) && Utils.ConvertToFloat(expenseAmount) > 0 &&
        !Utils.IsNullOrEmpty(expenseDescription)
    } else if (isUAEBusiness && !addVantExpenses === true) {
      isDetailsFilled = Utils.ConvertToFloat(expenseAmount) > 0 &&
        !Utils.IsNullOrEmpty(expenseDescription)
    }
    else {
      isDetailsFilled =
        Utils.ConvertToFloat(expenseAmount) > 0 &&
        !Utils.IsNullOrEmpty(expenseCategory) &&
        !Utils.IsNullOrEmpty(expenseDescription)
    }
    setSaveExpenseDisabled(!isDetailsFilled);
  };

  const handleCloseMessage = async () => {
    setMsgOpen(false);
    setAlertTitle("");
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
      return;
    }
    if (addExpense && expenseSaved) {
      closeExpenseSetup();
      var startDt = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });;
      var endDt = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      await fetchExpenseDetails(startDt, endDt);
    }
  };

  const handleCloseConfirm = async (value) => {
    setShowConfirm(false);
    switch (value) {
      case ConfirmTypes.OK:
      case ConfirmTypes.YES:
        switch (expenseAction) {
          case expenseActionsType.DeletePhoto:
            setIsLoading(true);
            var result = await postAsync("Expense/RemoveExpenseDocuments", {
              ImageUrl: expenseImage,
            });
            setIsLoading(false);
            if (result.error) {
              setIsNavigateToLogin(result.isNavigateToLogin);
              setMsgText(result.errorMessage);
              setMsgOpen(true);
              return;
            }
            if (result.data.isDeleted) {
              setExpenseImage("");
            }
            break;
          case expenseActionsType.Void:
            await submitExpenseDetails("Expense voided successfully");
            break;
        }
        break;
    }
  };

  const closeImage = () => {
    setEnlargeImage(false);
  };

  const openImage = () => {
    setEnlargeImage(true);
  };

  const backNavigation = () => {
    navigate(OverviewPage.Path, {});
  };
  const handleAddVantExpenses = (event) => {
    var isChecked = event.target.checked;
    if (isChecked) {
      setAddVantExpenses(true);
      setExpenseAmount("")
    } else {
      setAddVantExpenses(false);
      setTrnNumber();
      setInvoiceNumber();
      setTaxableAmount()
      setVATNumber();
      setExpenseAmount("");
    }
  }
  const handleVatTotalAmount = (value) => {
    setVATNumber(value);
    var taxAmount = taxableAmount;
    var vatnumber = value
    if (!Utils.IsNullOrEmpty(taxAmount) && !Utils.IsNullOrEmpty(vatnumber)) {
      var total = taxAmount + vatnumber;
      setExpenseAmount(total);
    } else if (!Utils.IsNullOrEmpty(taxAmount) && Utils.IsNullOrEmpty(vatnumber)) {
      setExpenseAmount(0);
    } else {
      setExpenseAmount(0);
    }
  }

  const handleVatTotalprice = (value) => {
    setTaxableAmount(value);
    var taxAmount = value;
    var vatnumber = VATNumber
    if (!Utils.IsNullOrEmpty(taxAmount) && !Utils.IsNullOrEmpty(vatnumber)) {
      var total = taxAmount + vatnumber;
      setExpenseAmount(total);
    } else if (!Utils.IsNullOrEmpty(taxAmount) && Utils.IsNullOrEmpty(vatnumber)) {
      setExpenseAmount(0);
    } else {
      setExpenseAmount(0);
    }
  }

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgTitle={alertTitle}
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={() => handleCloseMessage()}
      />
      <ConfirmPopup
        confirmOpen={showConfirm}
        confirmText={confirmText}
        onConfirmClose={handleCloseConfirm}
      />

      {/* Image Dialog */}
      <ImageEnlarge
        open={enlargeImage}
        imgSrc={expenseImage}
        onClose={closeImage}
      />

      <BootstrapDialog
        onClose={closeExpenseSetup}
        aria-labelledby="customized-dialog-title"
        open={addExpense}
        className="customerForm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeExpenseSetup}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Expense Setup
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className="DialogContent font-size-14">
          <Stack
            sx={{ minWidth: 120 }}
            spacing={2}
            className={expenseEdit ? "disabled-form" : ""}
          >
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  id="expenseDate"
                  disableFuture={true}
                  label={isUAEBusiness ? "Expense / Invoice Date" : "Expense Date"}
                  value={expenseDate}
                  onChange={(newValue) => setExpenseDate(newValue)}
                  closeOnSelect={true}
                />
              </LocalizationProvider>
            </FormControl>
            {(isAutomobileBusiness && !isUAEBusiness === true) && (
              <Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  className="justify-content-between"
                >
                  <Typography
                    level="h3"
                    component="h3"
                    fontSize="2rem"
                    className="h3"
                  >
                    Assign to Job Card?
                  </Typography>
                  <Switch
                    edge="end"
                    checked={assignToJobcard}
                    onClick={() => setAssignToJobcard(!assignToJobcard)}
                  />
                </Stack>
                {assignToJobcard && (
                  <Grid style={{ margin: "0" }}>
                    <FormControl fullWidth>
                      <InputLabel>JobCard Number</InputLabel>
                      <Select
                        id="expenseJobCardNumber"
                        value={expenseJobCardNumber}
                        label="JobCard Number"
                        variant="outlined"
                        onChange={(e) =>
                          setExpenseJobCardNumber(e.target.value)
                        }
                      >
                        {jobcards.map((jobcard) => (
                          <MenuItem value={jobcard.jobcardNumber}>
                            <Tooltip title={jobcard.vehicleDetails.number}>
                              {jobcard.jobcardNumber}
                            </Tooltip>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Stack>
            )}
            {isUAEBusiness === true && (
              <Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  className="justify-content-between"
                >
                  <Typography
                    level="h3"
                    component="h3"
                    fontSize="2rem"
                    className="h3"
                  >
                    Add VAT Expenses
                  </Typography>
                  <Switch
                    edge="end"
                    checked={addVantExpenses}
                    onClick={handleAddVantExpenses}
                  />
                </Stack>
                {addVantExpenses && (
                  <Grid style={{ margin: "0" }}>
                    <Stack>
                      <FormControl fullWidth>
                        <NumericTextField
                          id="TRNNUMBER"
                          label="TRN Number"
                          variant="outlined"
                          value={trnNumber}
                          error={!trnNumber}
                          setValue={setTrnNumber}
                          inputProps={{ maxLength: 17 }}
                        />
                      </FormControl>
                    </Stack>
                    <br />
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          id="InvoiceNumber"
                          label="Invoice Number"
                          variant="outlined"
                          value={invoiceNumber}
                          inputProps={{ maxLength: 50 }}
                          error={!invoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                        />
                      </FormControl>

                    </Stack>
                    <br />
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <DecimalEntryField
                          id="TaxableAmount"
                          label="Taxable Amount"
                          variant="outlined"
                          setError={!taxableAmount}
                          value={taxableAmount}
                          setValue={handleVatTotalprice}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <DecimalEntryField
                          id="VAT"
                          label="VAT Amount"
                          variant="outlined"
                          setError={!VATNumber}
                          value={VATNumber}
                          setValue={handleVatTotalAmount}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                )}
              </Stack>
            )}

            {!isUAEBusiness === true && (
              <>
                <Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-content-between"
                  >
                    <Typography
                      level="h3"
                      component="h3"
                      fontSize="2rem"
                      className="h3"
                    >
                      Create New Category?
                    </Typography>
                    <Switch
                      edge="end"
                      checked={newCategory}
                      onClick={(e) => handleNewCategory(e)}
                    />
                  </Stack>
                </Stack>
                {newCategory && (
                  <FormControl fullWidth>
                    <TextField
                      id="newCategory"
                      label="New Category"
                      variant="outlined"
                      required
                      value={expenseCategory}
                      onChange={(e) => setExpenseCategory(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                )}
                {!newCategory && (
                  <Stack style={{ margin: "0" }}>
                    <InputLabel>Category *</InputLabel>
                    <Select
                      id="expenseCategory"
                      value={expenseCategory}
                      required
                      variant="outlined"
                      onChange={(e) => setExpenseCategory(e.target.value)}
                    >
                      {categories.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </Stack>
                )}
              </>
            )}
            {addVantExpenses ? (
              <FormControl fullWidth>
                <DecimalEntryField
                  id="expenseAmount"
                  label="Expense Amount"
                  variant="outlined"
                  value={expenseAmount}
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            ) : (
              <FormControl fullWidth>
                <DecimalEntryField
                  id="expenseAmount"
                  label="Expense Amount"
                  variant="outlined"
                  value={expenseAmount}
                  setError={!expenseAmount}
                  setValue={setExpenseAmount}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            )}

            <FormControl fullWidth>
              <TextField
                type="text"
                id="expenseDescription"
                label="Expense Description"
                variant="outlined"
                value={expenseDescription}
                setError={!expenseDescription}
                onChange={(e) => setExpenseDescription(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <Stack style={{ margin: "0" }}>
              <InputLabel>Select Payment Method</InputLabel>
              <Select
                id="expensePaymentMethod"
                variant="outlined"
                value={selectedPaymentMethod}
                onChange={(e) => setSelectedPaymentMethod(e.target.value)}
              >
                {!Utils.IsNullOrEmptyArray(paymentMethodsArray) &&
                  paymentMethodsArray.map((method) => (
                    <MenuItem value={method}>{method}</MenuItem>
                  ))}
              </Select>
            </Stack>
            {Utils.IsNullOrEmpty(expenseImage) && (
              <Stack direction="row" spacing={2} justifyContent="end">
                <label style={{ cursor: "pointer" }}>
                  <AddAPhotoIcon className="icon20" />
                  <Input
                    type="file"
                    name="myImage"
                    className="hide"
                    inputProps={{
                      accept: "image/png, image/jpeg, image/ico, image/jpg",
                    }}
                    onChange={(e) => UploadExpenseImage(e)}
                  />
                </label>
              </Stack>
            )}
          </Stack>
          <Grid>
            {!Utils.IsNullOrEmpty(expenseImage) && (
              <div className="card imgBox">
                <img
                  alt="not found"
                  width={"150px"}
                  src={expenseImage}
                  onClick={openImage}
                />
                <br />
                {!expenseEdit && (
                  <button
                    className="mt-1 btn btn-default"
                    onClick={deleteExpensePhoto}
                  >
                    <DeleteIcon className="icon20" />
                  </button>
                )}
              </div>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {isVoidExpense === true && (
            <>
              <FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  className="btn btn-secondary"
                  onClick={closeExpenseSetup}
                >
                  Cancel
                </Button>
              </FormControl>
              <FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  className="btn btn-primary"
                  disabled={saveExpenseDisabled}
                  onClick={() => saveExpense(expenseEdit)}
                >
                  {expenseEdit ? "Void" : "Save"}
                </Button>
              </FormControl>
            </>
          )}
        </DialogActions>
      </BootstrapDialog>
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Overview} />
          <Grid xs={12} className="content-sec c-sec2">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              paddingBottom="15px"
            >
              <Grid container>
                <Grid container className="title-sec">
                  <Grid xs={12} md={8} lg={8}>
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="2rem"
                    >
                      <Button onClick={backNavigation} className="back-btn">
                        <NavigateBeforeIcon />
                      </Button>
                      Expense
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={4}
                    lg={4}
                    className="form-inline text-right bt-xs-1 date-picker-sec"
                    style={{
                      "align-self": "inherit",
                      "justify-content": "end",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="end"
                      style={{ height: "100%" }}
                      paddingRight={2}
                    >
                      <Stack alignSelf="center" style={{ marginRight: "10px" }}>
                        <Button
                          className="btn btn-secondary"
                          variant="contained"
                          onClick={() => openExpenseSetup()}
                        >
                          <AddCircleIcon
                            fontSize="large"
                            style={{ color: "#2196f3" }}
                          />
                          <span className="hidden-xs ml-h">Add</span>
                        </Button>
                      </Stack>
                      <DateRangePicker
                        showLeadingZeros={true}
                        onChange={onDateChange}
                        maxDate={new Date()}
                        value={selectedDate}
                        locale={CountryHelper.GetDateCulture(countryInfo.Culture)}
                        clearIcon={null}
                        calendarIcon={
                          <CalendarMonthOutlinedIcon
                            style={{
                              "font-size": "24px",
                              "align-self": "center",
                              color: "#666666",
                            }}
                          />
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="font-size-14 data-grid-box" style={{
                  marginTop: "10px", marginRight: "20px", marginLeft: "10px"
                }}>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row.id}
                      rows={filteredExpenseList}
                      columns={columns}
                      onCellClick={(e) => {
                        openExpenseSetup(e);
                      }}
                      disableColumnFilter={true}
                      disableColumnMenu={true}
                      rowSelection={false}
                      sx={{
                        boxShadow: 1,
                        border: 1,
                        borderColor: "primary.light",
                        "& .MuiDataGrid-cell:hover": {
                          color: "primary.main",
                        },
                      }}
                      slots={{ toolbar: CustomToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          showFilters: false,
                        },
                      }}
                      className="data-grid"
                    />
                  </Box>
                </Grid>
                <Grid container justifyContent="end">
                  <Stack padding="0 2rem" direction="row">
                    <Typography
                      level="h2"
                      className="h2"
                      component="h4"
                      fontWeight="600"
                      fontSize="16px"
                    >
                      {"Total : " +
                        Utils.GetCurrency(expenseTotal, countryInfo)}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}

export default Expense;
