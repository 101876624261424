import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import Utils from "../core/Utils";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { getBranch, getBusiness } from "../data/localSettingsActions";
import BusinessHelper from "../core/BusinessHelper";
import { InputLabel } from "@mui/material";
import SalesInfoComponent from "./common/SalesInfoComponent";
import { ServiceItemTypes, UEABusinessSetTaxPercent } from "../core/Constants";
import { getServiceItemFromProduct } from "../helpers/productHelper";
import CountryHelper from "../core/CountryHelper";

const CustomProduct = ({ open, onClose, onAdd }) => {
  const [measuringUnit, setMeasuringUnit] = useState("Number");
  const [productType, setProductType] = useState(ServiceItemTypes.Spare);
  const [quantity, setQuantity] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [purchasingCost, setPurchasingCost] = useState("");
  const [taxPercent, setTaxPercent] = useState("");
  const [showLabour, setShowLabour] = useState(false);
  const [addDisabled, setAddDisabled] = useState(true);
  const [isPurchasingCostRequired, setIsPurchasingCostRequired] = useState(
    productType === "Spare"
  );
  const [isAutomobileBusiness, setIsAutomobileBusiness] = useState(false);
  const [isDescriptionInInvoice, setIsDescriptionInInvoice] = useState(false);
  const [inclusiveOfTax, setInclusiveOfTax] = useState(false);
  const [totalPrice, setTotalPrice] = useState("");
  const [taxAmount, setTaxAmount] = useState("");

  useEffect(() => {
    setBusinessCatrgory();
  }, []);

  const setBusinessCatrgory = () => {
    var bus = getBusiness();
    setIsAutomobileBusiness(BusinessHelper.IsAutomobile(bus));
    var branch = getBranch();
    setIsDescriptionInInvoice(branch.showItemDescriptionInInvoice);
  };

  useEffect(() => {
    onSelectService();
  }, [productType]);

  useEffect(() => {
    checkDetailsFilled();
  }, [productName, quantity, productType, purchasingCost, productDescription]);

  const resetValues = () => {
    setProductName("");
    setProductDescription("")
    setQuantity("");
    setMeasuringUnit("Number");
    setProductType(ServiceItemTypes.Spare);
    setShowLabour(false);
    setSellingPrice("");
    setPurchasingCost("");
    setTaxPercent("");
    setMeasuringUnit("Number");
    setTotalPrice("");
    setTaxAmount("0.00");
  };

  const checkDetailsFilled = () => {
    var disable =
      Utils.IsNullOrEmpty(productName) ||
      Utils.ConvertToFloat(sellingPrice) === 0 ||
      (productType === "Spare" && Utils.ConvertToFloat(purchasingCost) === 0) ||
      Utils.ConvertToFloat(quantity) === 0;
    setAddDisabled(disable);
  };

  const onSelectService = () => {
    switch (productType) {
      case ServiceItemTypes.Spare:
        setShowLabour(false);
        setIsPurchasingCostRequired(true);
        break;
      case ServiceItemTypes.Service:
        setShowLabour(isAutomobileBusiness);
        setIsPurchasingCostRequired(false);
        break;
      case ServiceItemTypes.Labour:
        setShowLabour(isAutomobileBusiness);
        setIsPurchasingCostRequired(false);
        break;
      case ServiceItemTypes.Consumable:
        setShowLabour(false);
        setIsPurchasingCostRequired(false);
        break;
    }
  };

  const getProductObject = () => {
    var _purchasingCost = Utils.ConvertToFloat(purchasingCost);
    var _quantity = Utils.ConvertToFloat(quantity);
    var _sellingPrice = Utils.ConvertToFloat(sellingPrice);
    var _taxPercent = Utils.ConvertToFloat(taxPercent);
    var _priceIncludingTax = Utils.ConvertToFloat(totalPrice);
    var product = {
      cost: _purchasingCost,
      description: productDescription,
      shortDesc: productName,
      hsn: "",
      price: _sellingPrice,
      class: "",
      code: "",
      department: "",
      fineline: "",
      id: Utils.CreateGuid(),
      retailPrice: 0,
      serviceTime: 0,
      measuringUnit: measuringUnit,
      service: productType === ServiceItemTypes.Service,
      labour: productType === ServiceItemTypes.Labour,
      isMaterial: productType === ServiceItemTypes.Consumable,
      taxPercent: Utils.IsNullOrEmpty(taxPercent) && CountryHelper.IsBusinessCountryUAE() ? UEABusinessSetTaxPercent : _taxPercent,
      inclusiveOfTax: inclusiveOfTax,
      priceIncludingTax: inclusiveOfTax ? _priceIncludingTax : 0,
    };
    return { product: product, qty: _quantity };
  };

  const save = () => {
    if (addDisabled) {
      return;
    }
    var result = getProductObject();
    var serviceItem = getServiceItemFromProduct(
      result.product,
      result.qty,
      true
    );
    if (!Utils.IsNullOrEmptyObject(serviceItem)) {
      onAdd(serviceItem);
      resetValues();
    }
  };

  const onForceClose = () => {
    onClose();
  };

  const onMeasuringUnitChange = (event) => {
    setMeasuringUnit(event.target.value);
  };

  useEffect(() => {
    checkDetailsFilled();
  }, [taxPercent, sellingPrice, totalPrice]);

  return open === true ? (
    <Modal
      open={open}
      onClose={() => onForceClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="cs-modal">
        <DialogTitle id="customized-dialog-title" onClose={onForceClose}>
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Custom Product
          </Typography>
        </DialogTitle>
        <DialogContent dividers className="font-size-14">
          <Stack sx={{ minWidth: 120 }} spacing={2}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              >
                <FormControlLabel
                  value={ServiceItemTypes.Spare}
                  control={<Radio />}
                  label={isAutomobileBusiness ? "Spare" : "Product"}
                />
                <FormControlLabel
                  value={ServiceItemTypes.Service}
                  control={<Radio />}
                  label="Service"
                />
                {showLabour && (
                  <FormControlLabel
                    value={ServiceItemTypes.Labour}
                    control={<Radio />}
                    label="Labour"
                  />
                )}
                <FormControlLabel
                  value={ServiceItemTypes.Consumable}
                  control={<Radio />}
                  label={isAutomobileBusiness ? "Consumable" : "Material"}
                />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="csProductName"
                label="Name"
                value={productName}
                error={!productName}
                variant="outlined"
                onChange={(e) => setProductName(e.target.value)}
              />
            </FormControl>
            {isDescriptionInInvoice && (
              <FormControl fullWidth>
                <TextField
                  id="csProductDescription"
                  label="Description"
                  value={productDescription}
                  variant="outlined"
                  onChange={(e) => setProductDescription(e.target.value)}
                />
              </FormControl>
            )}

            <SalesInfoComponent
              cost={purchasingCost}
              setCost={setPurchasingCost}
              customProduct={true}
              costMandatory={isPurchasingCostRequired}
              inclusiveOfTax={inclusiveOfTax}
              setInclusiveOfTax={setInclusiveOfTax}
              priceExclusive={sellingPrice}
              setPriceExclusive={setSellingPrice}
              taxPercentage={taxPercent}
              setTaxPercentage={setTaxPercent}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              taxAmount={taxAmount}
              setTaxAmount={setTaxAmount}
              qty={quantity}
              setQTY={setQuantity}
              isUAEBusiness={CountryHelper.IsBusinessCountryUAE()}
            />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Measuring Unit
              </InputLabel>
              <Select
                id="measuringUnit"
                value={measuringUnit}
                required
                label="Measuring Unit"
                variant="outlined"
                onChange={(e) => onMeasuringUnitChange(e)}
              >
                <MenuItem value="Number">Number</MenuItem>
                <MenuItem value="Gram">Gram</MenuItem>
                <MenuItem value="Kilogram">Kilogram</MenuItem>
                <MenuItem value="Millilitre">Millilitre</MenuItem>
                <MenuItem value="Litre">Litre</MenuItem>
                <MenuItem value="Meter">Meter</MenuItem>
              </Select>
            </FormControl>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <FormControl>
                <Button
                  variant="outlined"
                  className="btn btn-secondary"
                  onClick={onForceClose}
                >
                  Close
                </Button>
              </FormControl>
              <FormControl>
                <Button
                  variant="contained"
                  disabled={addDisabled}
                  className="btn btn-primary"
                  onClick={save}
                >
                  Add
                </Button>
              </FormControl>
            </Stack>
          </Stack>
        </DialogContent>
      </Box>
    </Modal>
  ) : null;
};

export default CustomProduct;
